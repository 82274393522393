import React, { Component } from 'react';

class Footer extends Component {
    state = {  }
    render() { 
        let style = {
            border:0,
            width:"100%",
            height:450
        };
        return (  
            <div className="footer">
                <div className="footer-right alternative">
                    <a href="contact" className="button-link-large" id="erstgespraechButtonBreit">
                        Erstgespräch vereinbaren
                    </a>
                    <div className="footer-contact">
                        <b>Mag.a ANNA MARIA DIEM</b><br />
                        Ziegelofengasse 33/6<br />
                        1050 Wien<br/>
                        <a href="TEL:+436763747720" className="button-link">+43 676 374 7720</a><br />
                        <a href="MAILTO:psychotherapie@annamariadiem.at" className="button-link">psychotherapie@annamariadiem.at</a>
                    </div>
                </div>
                <div className="footer-left">
                <iframe className="maps-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5624.629009796609!2d16.357178937425015!3d48.190575688974576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da9a0a7975b21%3A0x5612bd9b4a66e44f!2sMag.a%20Anna%20Maria%20Diem%20-%20Psychotherapeutin!5e0!3m2!1sde!2sat!4v1618228845308!5m2!1sde!2sat" style={style} allowFullScreen="" loading="lazy" />
                </div>
                <div className="footer-right has-alternative">
                    <a href="contact" className="button-link-large" id="erstgespraechButtonBreit">
                        Erstgespräch vereinbaren
                    </a>
                    <div className="footer-contact">
                        <b>Mag.a ANNA MARIA DIEM</b><br />
                        Ziegelofengasse 33/6<br />
                        1050 Wien<br/>
                        <a href="TEL:+436763747720" className="button-link">+43 676 374 7720</a><br />
                        <a href="MAILTO:psychotherapie@annamariadiem.at" className="button-link">psychotherapie@annamariadiem.at</a>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Footer;