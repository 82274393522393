import React, { Component } from 'react';

class Impressum extends Component {
    state = {  }
    render() { 
        return (  
            <div className="text block-text" id="top">
                
                { window.outerWidth >= 910 ?
                    <span style={{textAlign: "center"}}> <h1>www.annamariadiem.at</h1></span> : <h2>www.annamariadiem.at</h2> }
                <h2>Impressum</h2>
                <b>Mag.<sup>a</sup> ANNA MARIA DIEM</b> <br />
                Ziegelofengasse 33/6 <br />
                1050 Wien <br />
                <b>Tel:</b> +43 676 374 7720 <br />
                <b>E-Mail:</b> psychotherapie@annamariadiem.at<br />
                <b>Unternehmensgegenstand:</b> Psychotherapie <br />
                <b>Mitgliedschaften:</b> Psychoanalytisches Seminar Innsbruck, Imago Gesellschaft Österreich <br />
                <b>Berufsrecht:</b> Psychotherapiegesetz BGBl.Nr. 361/1990 ST0151 <br />
                <b>Aufsichtsbehörde:</b> Psychotherapiebeirat Wien <br />
                <b>Berufsbezeichnung:</b> Psychotherapeutin <br />
                <b>Verleihungsstaat:</b> Österreich <br />
                <br />
                <b>Website & Fotos: </b> Georg Reichert
                <br /><br />
                Die Inhalte der Website wurden sorgfältig zusammengestellt, dennoch sind Änderungen und Irrtümer vorbehalten und es wird keine Haftung für etwaige fehlerhafte Angaben übernommen.
                <br /><br />
                Die Inhalte der Website sind nur für den persönlichen Gebrauch bestimmt und urheberrechtlich geschützt. Jede weitere Nutzung, insbesondere die Speicherung in Datenbanken, Veröffentlichung, Vervielfältigung und jede Form von gewerblicher Nutzung, sowie die Weitergabe an Dritte, auch in beschränkter oder in abgeänderter Form, ist ohne Zustimmung der Inhaberin untersagt.
                <br /><br />
                Inhalte externer Seiten, die über Verlinkungen erreicht werden können, unterliegen nicht der Verantwortung der Seiteninhaberin.
                <br /><br />
                Es gilt österreichisches Recht. Gerichtsstand ist Wien.
                    <h2>Datenschutzerklärung</h2>
                        Der Schutz Ihrer persönlichen Daten ist mir ein besonderes Anliegen. Ich verarbeite Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informiere ich Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen meiner Website. 
                <br /><br />
                Beim Besuch meiner Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Diese Daten werden von mir nicht weiterverarbeitet.
                <br /><br />
                Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit mir aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei mir gespeichert. Diese Daten gebe ich nicht weiter. 
                <br /><br />
                Diese Website verwendet keine Cookies.
                <br /><br />
                Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei mir oder der Datenschutzbehörde beschweren.           
            </div>
        );
    }
}
 
export default Impressum;