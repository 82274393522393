import React from 'react';

class NotFound extends React.Component {
    state = {  }
    render() { 
        return (  
            <div style={{textAlign:"center", padding: "5%"}}>
                <h1>
                    Seite nicht gefunden
                </h1>
                Die gesuchte Seite existiert leider nicht.
            </div>
        );
    }
}
 
export default NotFound;