import React from 'react';

class Main extends React.Component {
    state = {  
        variableBackgroundColor: false
    }

    render() { 
        return (
            <div className="fade-in" id="top">
                { this.props.data.map(subsection => (
                    <div className={ subsection.classes } id={ subsection.id } dangerouslySetInnerHTML={ {__html:subsection.content} }></div>
                ))}
            </div>
        );
    }
}
 
export default Main;