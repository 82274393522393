import React, { Component } from 'react';
import { HashLink } from "react-router-hash-link";

class SideNav extends Component {
    constructor(props) {
        super(props);
        console.log(this.state);
        this.updateLinks = this.updateLinks.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        //this.sideNavBarClick = this.sideNavBarClick.bind(this);
    }
      
    componentDidMount() {
        window.onscroll = function() {
            if (window.outerWidth > 910) {
                if(!this.state.menuActive && window.pageYOffset >= 90) {
                    this.setState({menuActive:true});
                    let menuButton = document.getElementById("menu-button");
                    menuButton.classList.remove("menu-button-inactive");
                    menuButton.classList.add("fade-in");
                    menuButton.classList.remove("fade-out");
                    clearTimeout(this.state.timeOutId);
                } else if (this.state.menuActive && window.pageYOffset <90) {
                    this.setState({menuActive:false});
                    let menuButton = document.getElementById("menu-button");
                    let id = setTimeout(() => menuButton.classList.add("menu-button-inactive"), 900);
                    this.setState({timeOutId: id});
                    menuButton.classList.remove("fade-in");
                    menuButton.classList.add("fade-out");
                }
            }
        };
        window.onscroll = window.onscroll.bind(this);
        this.updateLinks(this.props.activePage);
    }
    /*
    sideNavBarClick(event) {
        if (this.state.lastClickedMenuElement != event.target.id) {
            this.setState({lastClickedMenuElement: event.target.id});
            event.preventDefault();
            event.nativeEvent.stopImmediatePropagation();
            event.stopPropagation();
        } else {
            this.setState({lastClickedMenuElement: ""});
        }
        console.log(this.state.lastClickedMenuElement);
    }
    */
    componentWillUnmount() {
        window.onscroll = null;
    }

    state = {  
        activePage: "start",
        links: document.getElementsByClassName("nav-link"),
        menuActive: false,
        showMenu: false,
        eventHandlerSet: false,
        timeOutId: 0,
        lastClickedMenuElement: ""
    }

    showMenu(event) {
            event.preventDefault();
            this.setState({
                showMenu: true,
        });
        document.addEventListener("click", this.closeMenu);
    }

    closeMenu() {
        if (this.state.eventHandlerSet){
            this.setState({ showMenu: false });
            document.removeEventListener('click', this.closeMenu);
            this.setState({eventHandlerSet: false});
        } else {
            this.setState({eventHandlerSet: true});
        }
    }

    updateLinks(id) {
        this.setState({
            activePage: id
        });
        for(let link of this.state.links) {
            if (link.id === id) {
                link.classList.add("active-nav-link");
            } else {
                link.classList.remove("active-nav-link");
            }
        }
    }

    render() { 
        return ( 
            <div className="menu">
                <div id="menu-button" className="menu-button-inactive" onClick={ this.showMenu }>
                    <img src="./icon/menu.png" />
                    {
                    this.state.showMenu ? (
                        <div className="side-navbar navbar fade-in-fast">
                            <ul>
                                <li id="start">
                                    <HashLink to="/#top" onClick={ () => {this.updateLinks("start")} }>
                                        Home
                                    </HashLink>
                                </li>
                                <li id="therapy-side">
                                    <HashLink to="/therapy#top" onClick={ () => { this.updateLinks("therapy-side")} }>
                                        Therapiemethoden
                                    </HashLink>
                                    <ul className="fade-in-fast">
                                        <li>
                                            <HashLink to="/therapy#therapy" onClick={ () => {this.updateLinks("therapy")} }>
                                                Psychotherapie
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/therapy#analyse" onClick={ () => {this.updateLinks("therapy")} }>
                                                Psychoanalyse
                                            </HashLink>
                                        </li>
                                        <li>
                                            <HashLink to="/therapy#imago" onClick={ () => {this.updateLinks("therapy")} }>
                                                Imago Paartherapie
                                            </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="offers">
                                    <HashLink to="/offers#top" onClick={ () => {this.updateLinks("offers")} }>
                                        Angebot & Rahmenbedingungen
                                    </HashLink>
                                    <ul className="fade-in-fast">
                                        <li>
                                        <HashLink to="/offers#single" onClick={ () => {this.updateLinks("offers")} }>
                                            Einzeltherapie
                                        </HashLink>
                                        </li>
                                        <li>
                                        <HashLink to="/offers#couple" onClick={ () => {this.updateLinks("offers")} }>
                                            Paartherapie
                                        </HashLink>
                                        </li>
                                        <li>
                                        <HashLink to="/offers#conditions" onClick={ () => {this.updateLinks("offers")} }>
                                            Rahmenbedingungen
                                        </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="aboutme">
                                    <HashLink to="/aboutme#top" onClick={ () => {this.updateLinks("aboutme")} }>
                                        Über mich
                                    </HashLink>
                                    <ul className="fade-in-fast">
                                        <li>
                                        <HashLink to="/aboutme#resume" onClick={ () => {this.updateLinks("aboutme")} }>
                                            Werdegang
                                        </HashLink>
                                        </li>
                                        <li>
                                        <HashLink to="/aboutme#contributions" onClick={ () => {this.updateLinks("aboutme")} }>
                                            Beiträge
                                        </HashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li id="contact">
                                    <HashLink to="/contact#top" onClick={ () => {this.updateLinks("contact")} }>
                                        Kontakt
                                    </HashLink>
                                    <ul className="fade-in-fast">
                                        <li>
                                        <HashLink to="/contact#contactdata" onClick={ () => {this.updateLinks("contact")} }>
                                            Kontaktdaten
                                        </HashLink>
                                        </li>
                                        <li>
                                        <HashLink to="/contact#links" onClick={ () => {this.updateLinks("contact")} }>
                                            Links
                                        </HashLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    ) : ( null )
                }
                </div>
                
            </div>
        );
    }
}
 
export default SideNav;