import React from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import Main from './Main';
import NotFound from './NotFound';
import Nav from './Nav';
import SideNav from'./SideNav';
import Footer from './Footer';
import Impressum from './Impressum';

import data from "./data/data.json";

class App extends React.Component {
    state = {  }
    render() { 
        return (  
            <Router basename="/prototypeV2">
                <Switch>
                    <Route path="/" exact render={(props) => (<SideNav {...props} activePage={ "start" } />)}></Route>
                    <Route path="/therapy" render={(props) => (<SideNav {...props} activePage={ "therapy" } />)}></Route>
                    <Route path="/offers" render={(props) => (<SideNav {...props} activePage={ "offers" } />)}></Route>
                    <Route path="/aboutme" render={(props) => (<SideNav {...props} activePage={ "aboutme" } />)}></Route>
                    <Route path="/contact" render={(props) => (<SideNav {...props} activePage={ "contact" } />)}></Route>
                    <Route path="/" render={(props) => (<SideNav {...props} activePage={ "notfound" } />)}></Route>
                </Switch>
                <div className="container">
                    <Switch>
                        <Route path="/" exact render={(props) => (<Nav {...props} activePage={ "start" } />)}></Route>
                        <Route path="/therapy" render={(props) => (<Nav {...props} activePage={ "therapy" } />)}></Route>
                        <Route path="/offers" render={(props) => (<Nav {...props} activePage={ "offers" } />)}></Route>
                        <Route path="/aboutme" render={(props) => (<Nav {...props} activePage={ "aboutme" } />)}></Route>
                        <Route path="/contact" render={(props) => (<Nav {...props} activePage={ "contact" } />)}></Route>
                        <Route path="/" render={(props) => (<Nav {...props} activePage={ "notfound" } />)}></Route>
                    </Switch>
                    <div className="main">
                        <Switch>
                            <Route path="/" exact component={(props) => (<Main {...props} data={ data.start } />)}></Route>
                            <Route path="/therapy" component={(props) => (<Main {...props} data={ data.therapy } />)}></Route>
                            <Route path="/offers" component={(props) => (<Main {...props} data={ data.offers } />)}></Route>
                            <Route path="/aboutme" component={(props) => (<Main {...props} data={ data.aboutme } />)}></Route>
                            <Route path="/contact" component={(props) => (<Main {...props} data={ data.contact } />)}></Route>
                            <Route path="/impressum" component={ Impressum }></Route>
                            <Route path="/" component={ NotFound }></Route>
                        </Switch>
                    </div>
                    <Footer />
                </div>
                <div style={{ textAlign:'center', paddingBottom: 20 }}>
                &#169; 2021 Anna Maria Diem, Alle Rechte vorbehalten <br />
                    <HashLink to="/impressum#top">Impressum & Datenschutz</HashLink>
                </div>
            </Router>
        );
    }
}

export default App;