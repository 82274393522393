import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.updateLinks = this.updateLinks.bind(this);
    }

    componentDidMount() {
        this.updateLinks(this.props.activePage);
    }

    state = {  
        activePage: "start",
        links: document.getElementsByClassName("nav-link")
    }

    updateLinks(id) {
        this.setState({
            activePage: id
        });
        for(let link of this.state.links) {
            if (link.id === id) {
                link.classList.add("active-nav-link");
            } else {
                link.classList.remove("active-nav-link");
            }
        }
    }

    render() { 
        return (  
            <div className="navbar standard-navbar">
                <ul>
                    <li className="nav-link" id="start">
                        <Link to="/" onClick={ () => {this.updateLinks("start")} }>
                            Home
                        </Link>
                    </li>
                    <li className="nav-link" id="therapy">
                        <Link to="/therapy" onClick={ () => {this.updateLinks("therapy")} }>
                            Therapiemethoden
                        </Link>
                        <ul className="fade-in-fast">
                            <li className="nav-link">
                                <HashLink to="/therapy#therapy" onClick={ () => {this.updateLinks("therapy")} }>
                                    Psychotherapie
                                </HashLink>
                            </li>
                            <li className="nav-link">
                                <HashLink to="/therapy#analyse" onClick={ () => {this.updateLinks("therapy")} }>
                                    Psychoanalyse
                                </HashLink>
                            </li>
                            <li className="nav-link">
                                <HashLink to="/therapy#imago" onClick={ () => {this.updateLinks("therapy")} }>
                                    Imago Paartherapie
                                </HashLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-link" id="offers">
                        <Link to="/offers" onClick={ () => {this.updateLinks("offers")} }>
                            Angebot & Rahmenbedingungen
                        </Link>
                        <ul className="fade-in-fast">
                            <li>
                            <HashLink to="/offers#single" onClick={ () => {this.updateLinks("offers")} }>
                                Einzeltherapie
                            </HashLink>
                            </li>
                            <li>
                            <HashLink to="/offers#couple" onClick={ () => {this.updateLinks("offers")} }>
                                Paartherapie
                            </HashLink>
                            </li>
                            <li>
                            <HashLink to="/offers#conditions" onClick={ () => {this.updateLinks("offers")} }>
                                Rahmenbedingungen
                            </HashLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-link" id="aboutme">
                        <Link to="/aboutme" onClick={ () => {this.updateLinks("aboutme")} }>
                            Über mich
                        </Link>
                        <ul className="fade-in-fast">
                            <li>
                            <HashLink to="/aboutme#resume" onClick={ () => {this.updateLinks("aboutme")} }>
                                Werdegang
                            </HashLink>
                            </li>
                            <li>
                            <HashLink to="/aboutme#contributions" onClick={ () => {this.updateLinks("aboutme")} }>
                                Beiträge
                            </HashLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-link" id="contact">
                        <Link to="/contact" onClick={ () => {this.updateLinks("contact")} }>
                            Kontakt
                        </Link>
                        <ul className="fade-in-fast">
                            <li>
                            <HashLink to="/contact#contactdata" onClick={ () => {this.updateLinks("contact")} }>
                                Kontaktdaten
                            </HashLink>
                            </li>
                            <li>
                            <HashLink to="/contact#links" onClick={ () => {this.updateLinks("contact")} }>
                                Links
                            </HashLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }
}
 
export default Nav;